import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  max-width: 690px;

  h1 {
    display: flex;
    align-items: center;

    margin: 0;
    margin-top: 4px;

    font-size: 24px;
    font-family: Poppins;
    font-weight: bold;

    color: ${colors.gray600};
  }

  h2 {
    margin-top: 12px;

    font-size: 16px;
    color: ${colors.gray600};
    font-weight: 600;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 256px;

    border: 0;
    border-radius: 8px;

    margin-top: 36px;
    padding: 8px 24px;
    height: 40px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: medium;
    color: ${colors.white};

    background-color: ${colors.blue400};
    transition: background-color 0.2s;

    &:hover {
      background-color: ${colors.blue500};
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
`;

export const SupportLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 256px;
  height: 40px;

  border: 0;
  border-radius: 8px;

  margin-top: 8px;
  padding: 8px 24px;

  font-family: Poppins;
  font-size: 16px;
  font-weight: medium;
  color: ${colors.blue400};

  background-color: ${colors.white};
  transition: background-color 0.4s;

  border: 1px solid ${colors.blue400};

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${colors.blue400};
    color: ${colors.white};
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const DescricaoContainer = styled.div`
  width: 100%;
  max-width: 384px;

  margin-top: 24px;

  p {
    margin-top: 4px;

    font-size: 16px;

    color: ${colors.gray300};
    font-family: Poppins;

    span {
      color: ${colors.gray600};
      font-family: Poppins;
      font-weight: normal;
    }

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  p:last-child {
    margin-top: 8px;
  }
`;

export const DetailsContainer = styled.div`
  margin-top: 32px;

  p {
    font-size: 16px;

    color: ${colors.gray300};
    font-family: Poppins;

    span {
      color: ${colors.gray600};
      font-family: Poppins;
      font-weight: normal;
    }

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
`;
