import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { FaWhatsapp } from 'react-icons/fa';
import encodeUrl from 'encodeurl';

import ErrorImage from '@/images/paginas/rastreamento/resultado/errorPage/error-image.svg';

import { Container, SupportLink } from './styles';
import { SUPPORT_NUMBER } from '@/config';

interface IResultadoErrorPageProps {
  errorMessage: string;
  cpf: string;
  cnpj: string;
  notaFiscal: string;
}

const ResultadoErrorPage: React.FC<IResultadoErrorPageProps> = ({
  errorMessage,
  cpf,
  cnpj,
  notaFiscal,
}) => {
  const whatsappSupportLink = useMemo(() => {
    let encodedSupportMessage = '';

    if (cpf || cnpj) {
      let wppMessage = `Olá, preciso de ajuda para rastrear minha encomenda. O CPF/CNPJ é ${
        cpf || cnpj
      }`;

      if (notaFiscal) {
        wppMessage += ` e a nota fiscal é ${notaFiscal}.`;
      } else {
        wppMessage += `. Tentei pelo site mas não encontrou. `;
      }

      encodedSupportMessage = encodeUrl(wppMessage);
    } else {
      encodedSupportMessage = encodeUrl(
        'Olá, preciso de ajuda para rastrear minha encomenda. '
      );
    }

    return `https://api.whatsapp.com/send?phone=${SUPPORT_NUMBER}&text=${encodedSupportMessage}`;
  }, [cpf, cnpj, notaFiscal]);

  return (
    <Container>
      <img src={ErrorImage} alt="Alert error" />

      <p>{errorMessage || 'Erro interno do servidor.'}</p>

      <button type="button" onClick={() => navigate('/rastreamento')}>
        Fazer novo rastreamento
      </button>

      <SupportLink href={whatsappSupportLink} target="_blank" rel="noreferrer">
        <FaWhatsapp />
        Suporte via WhatsApp
      </SupportLink>
    </Container>
  );
};

export default ResultadoErrorPage;
