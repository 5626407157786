import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;

  > p {
    font-size: 16px;
    font-weight: normal;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: ${colors.blue400};

    max-width: fit-content;
    height: 40px;
    margin-top: 48px;
    padding: 8px 24px;

    border: 0;
    border-radius: 8px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: medium;
    color: ${colors.white};

    display: none;

    @media (max-width: 1024px) {
      display: flex;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 60px;
  }
`;

export const VerticalStepperContainer = styled.div``;

export const SupportLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  border-radius: 8px;

  max-width: fit-content;
  height: 40px;
  margin-top: 8px;
  padding: 8px 24px;

  font-family: Poppins;
  font-size: 16px;
  font-weight: medium;
  color: ${colors.blue400};

  background-color: ${colors.white};
  transition: background-color 0.4s;

  border: 1px solid ${colors.blue400};

  display: none;

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${colors.blue400};
    color: ${colors.white};
  }

  @media (max-width: 1024px) {
    display: flex;
  }
`;
