/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useQueryParam } from 'use-query-params';
import axios from 'axios';

import ResultadoErrorPage from '@/components/Pages/Resultado/ErrorPage';

import SEO from '@/components/SEO';
import Layout from '@/components/Layout';
import BreadCrumbs from '@/components/BreadCrumbs';
import ResultadoLeftSection from '@/components/Pages/Resultado/ResultadoLeftSection';
import ResultadoRightSection from '@/components/Pages/Resultado/ResultadoRightSection';

import {
  ResultadoWrapper,
  Container,
  BreadCrumbsContainer,
  ResultadoContainer,
} from '@/styles/pages/rastreamento/resultado';

import { IRastreamentoResponse } from '@/dtos/pages/resultado';

const pageBreadCrumbs = [
  { name: 'Página inicial', relativeURL: '/' },
  { name: 'Rastreamento', relativeURL: '/rastreamento' },
  {
    name: 'Resultado',
  },
];

const Resultado = (): JSX.Element => {
  const [cpf] = useQueryParam<string>('cpf');
  const [cnpj] = useQueryParam<string>('cnpj');
  const [tipo] = useQueryParam<string>('tipo');
  const [notaFiscal] = useQueryParam<string>('notaFiscal');

  const [serverErrorMessage, setServerErrorMessage] = useState('');

  const [rastreamentoResponse, setRastreamentoResponse] = useState(
    {} as IRastreamentoResponse
  );

  useEffect(() => {
    if (cpf) {
      pageBreadCrumbs[2] = {
        name: 'Encomendas',
        relativeURL: `/rastreamento/encomendas?cpf=${cpf}`,
      };
      pageBreadCrumbs[3] = {
        name: 'Resultado',
      };
    }
  }, [cpf]);

  useEffect(() => {
    async function getTrackingData() {
      const apiURL = cpf
        ? `/api/rastreamento/cpf/${tipo || 'destinatario'}`
        : `/api/rastreamento/cnpj/${tipo || 'destinatario'}`;

      try {
        const response = await axios.post(
          apiURL,
          {
            cpf,
            cnpj,
            notaFiscal,
          },
          {
            headers: {
              dominio: 'GEN',
              usuario: 'chatbot',
              senha: 'cbgen21',
            },
          }
        );

        const numEtapaAtual = response.data.ocorrenciaMaisRecente.etapa;
        const etapaAtual = response.data.etapas.find(
          (etapa: any) => etapa.etapa === numEtapaAtual
        ).nome;

        const formattedTrackingData = {
          ...response.data,
          etapaAtual,
        };

        // console.log('formattedTrackingData: ', formattedTrackingData);

        setRastreamentoResponse(formattedTrackingData);
      } catch (err: any) {
        const serverErrorMessage = err.response?.data.message;

        setServerErrorMessage(
          serverErrorMessage || 'Erro interno do servidor.'
        );
      }
    }

    if (cpf || cnpj || tipo || notaFiscal) {
      getTrackingData();
    }
  }, [cpf, cnpj, tipo, notaFiscal]);

  return (
    <>
      <SEO
        pageTitle={`Resultado do rastreamento`}
        title={`Resultado do rastreamento`}
        description="Verifique onde sua encomenda está a qualquer momento!"
        image="/images/components/SEO/og.png"
      />
      <Layout>
        <ResultadoWrapper>
          <Container>
            <BreadCrumbsContainer>
              <BreadCrumbs pageBreadCrumbs={pageBreadCrumbs} />
            </BreadCrumbsContainer>

            {serverErrorMessage ? (
              <ResultadoErrorPage
                errorMessage={serverErrorMessage}
                cpf={cpf}
                cnpj={cnpj}
                notaFiscal={notaFiscal}
              />
            ) : (
              <ResultadoContainer>
                <ResultadoLeftSection
                  cpf={cpf}
                  cnpj={cnpj}
                  notaFiscal={Number(notaFiscal)}
                  tipo={tipo}
                  rastreamentoResponse={rastreamentoResponse}
                />

                <ResultadoRightSection
                  cpf={cpf}
                  cnpj={cnpj}
                  notaFiscal={Number(notaFiscal)}
                  etapaData={rastreamentoResponse.etapaData}
                  etapas={rastreamentoResponse.etapas}
                  ocorrenciaMaisRecente={
                    rastreamentoResponse.ocorrenciaMaisRecente
                  }
                />
              </ResultadoContainer>
            )}
          </Container>
        </ResultadoWrapper>
      </Layout>
    </>
  );
};

export default Resultado;
