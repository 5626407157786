import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  max-width: fit-content;

  margin-top: 32px;

  p {
    margin-right: 14px;
  }

  button {
    transition: opacity 0.4s;

    :hover {
      opacity: 0.7;
    }
  }

  button + button {
    margin-left: 8px;
  }
`;
