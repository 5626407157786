import React, { useCallback, useMemo } from 'react';
import Step from '@material-ui/core/Step';
import Skeleton from 'react-loading-skeleton';

import CircleX from '@/images/paginas/rastreamento/resultado/x.svg';

import { Container, Stepper, OcorrenciaTitleContainer } from './styles';

import { IEtapas, IOcorrenciaMaisRecente } from '@/dtos/pages/resultado';

type HorizontalStepperProps = {
  etapas: IEtapas;
  ocorrenciaMaisRecente: IOcorrenciaMaisRecente;
};

const HorizontalStepper: React.FC<HorizontalStepperProps> = ({
  etapas,
  ocorrenciaMaisRecente,
}): JSX.Element => {
  const isCompleted = useMemo(() => {
    if (
      ocorrenciaMaisRecente &&
      ocorrenciaMaisRecente.etapa === 4 &&
      ocorrenciaMaisRecente.ocorrencia.tipoInformacao === 'info'
    ) {
      return true;
    }

    return false;
  }, [ocorrenciaMaisRecente]);

  const getStepErrorIcon = useCallback(
    (etapa: number) => {
      if (
        ocorrenciaMaisRecente.ocorrencia.tipoInformacao === 'alerta' &&
        ocorrenciaMaisRecente.etapa === etapa
      ) {
        return () => (
          <img src={CircleX} alt="alert circle" width={24} height={24} />
        );
      }

      return undefined;
    },
    [ocorrenciaMaisRecente]
  );

  return (
    <Container>
      <Stepper activeStep={0} orientation="horizontal">
        {!etapas &&
          [0, 1, 2, 3].map((number) => (
            <Step active={true} key={number}>
              <OcorrenciaTitleContainer
                hasOcorrencia={false}
                informationType="info"
                StepIconComponent={() => (
                  <Skeleton
                    width={24}
                    height={24}
                    style={{ borderRadius: 12 }}
                  />
                )}
              />
            </Step>
          ))}

        {etapas?.map(({ etapa }) => (
          <Step
            completed={etapa === 4 && isCompleted}
            active={true}
            key={etapa}
          >
            <OcorrenciaTitleContainer
              hasOcorrencia={etapa === ocorrenciaMaisRecente?.etapa}
              informationType={ocorrenciaMaisRecente?.ocorrencia.tipoInformacao}
              StepIconComponent={getStepErrorIcon(etapa)}
            />
          </Step>
        ))}
      </Stepper>
    </Container>
  );
};

export default HorizontalStepper;
