import styled from 'styled-components';
import StepContent from '@material-ui/core/StepContent';
import MuiStepper from '@material-ui/core/Stepper';
import { StepLabel } from '@material-ui/core';

import { colors } from '@/styles/GlobalStyle';

interface ITitleContainerProps {
  hasOcorrencia: boolean;
  informationType: 'info' | 'aviso' | 'alerta';
}

const MAPPED_COLOR = {
  info: colors.green200,
  aviso: colors.yellow200,
  alerta: colors.red,
};

export const Container = styled.div`
  width: 100%;
`;

export const Stepper = styled(MuiStepper)`
  padding: 0 !important;
  margin-top: 24px;

  .MuiStep-root.MuiStep-vertical {
    /* border: 1px solid tomato; */
    width: 100%;
    max-width: 550px;
    min-width: fit-content;
  }

  .MuiTypography-root.MuiStepLabel-label.MuiStepLabel-active.MuiTypography-body2.MuiTypography-displayBlock {
    color: ${colors.gray600};
    font-size: 16px;
    font-weight: 600;
    font-family: Poppins;
  }
`;

export const OcorrenciaTitleContainer = styled(StepLabel)<ITitleContainerProps>`
  // Título a direita da Bolinha
  .MuiStepLabel-label {
    margin-left: 20px;

    @media (max-width: 1024px) {
      margin-left: 10px;
    }
  }

  // Texto da Bolinha
  .MuiStepIcon-text {
    display: none;
  }

  .MuiStepIcon-root.MuiStepIcon-completed,
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${(props) =>
      props.hasOcorrencia
        ? `${MAPPED_COLOR[props.informationType]}`
        : colors.gray300};
  }

  // Bolinha do estado de completo/finalizado
  .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active.MuiStepIcon-completed {
    color: ${colors.green200};
  }

  :disabled {
    background-color: red;
  }
`;

export const OcorrenciaDescriptionContainer = styled(StepContent)`
  // Container por volta de toda a descrição
  .MuiCollapse-container.MuiStepContent-transition.MuiCollapse-entered {
    /* border: 1px solid blue; */
    width: 100%;
    max-width: 480px;

    margin-left: 20px;

    @media (max-width: 1024px) {
      margin-left: 10px;
    }

    @media (max-width: 400px) {
      max-width: 200px;
    }
  }

  p {
    // detalhes da ocorrência: 06/04/2021 - 17:48 | Filial: SPO | Jandira - SP
    &:first-child {
      display: flex;
      justify-content: space-between;

      color: ${colors.gray600};
      font-family: Poppins;
      font-size: 16px;
      font-weight: normal;

      width: 100%;

      @media (max-width: 500px) {
        font-size: 14px;
      }

      @media (max-width: 400px) {
        overflow: hidden;
        text-overflow: ellipsis;

        span:not(:nth-child(1)) {
          display: none;
        }
      }
    }

    // descrição da ocorrência
    & + p {
      display: flex;
      flex-direction: column;

      width: 100%;
      max-width: 379px;
      margin-top: 1px;
      font-size: 12px;

      color: ${colors.gray300};
      font-family: Poppins;

      b {
        display: inline;
      }

      @media (max-width: 400px) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
`;
