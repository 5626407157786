import styled from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 500px;

  p {
    max-width: 500px;

    margin-top: 32px;
    font-size: 16px;

    color: ${colors.gray600};
    font-family: Poppins;
    text-align: center;
  }

  p + button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    max-width: 256px;
    height: 40px;

    border: 0;
    border-radius: 8px;

    margin-top: 36px;
    padding: 8px 24px;

    font-family: Poppins;
    font-size: 16px;
    font-weight: medium;
    color: ${colors.white};

    background-color: ${colors.blue400};
    transition: background-color 0.2s;

    &:hover {
      cursor: pointer;
      background-color: ${colors.blue500};
    }
  }

  @media (max-width: 500px) {
    padding: 0 16px;
  }
`;

export const SupportLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 256px;
  height: 40px;

  border: 0;
  border-radius: 8px;

  margin-top: 8px;

  font-family: Poppins;
  font-size: 16px;
  font-weight: medium;
  color: ${colors.blue400};

  background-color: ${colors.white};
  transition: background-color 0.4s;

  border: 1px solid ${colors.blue400};

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${colors.blue400};
    color: ${colors.white};
  }
`;
