import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { FaWhatsapp } from 'react-icons/fa';
import encodeUrl from 'encodeurl';

import VerticalStepper from './VerticalStepper';

import { SUPPORT_NUMBER } from '@/config';

import {
  IEtapaData,
  IEtapas,
  IOcorrenciaMaisRecente,
} from '@/dtos/pages/resultado';

import { Container, SupportLink } from './styles';

type ResultadoRightSectionProps = {
  cpf: string;
  cnpj: string;
  notaFiscal: number;
  etapaData: IEtapaData;
  etapas: IEtapas;
  ocorrenciaMaisRecente: IOcorrenciaMaisRecente;
};

const ResultadoRightSection: React.FC<ResultadoRightSectionProps> = ({
  cpf,
  cnpj,
  notaFiscal,
  etapaData,
  etapas,
  ocorrenciaMaisRecente,
}): JSX.Element => {
  const whatsappSupportLink = useMemo(() => {
    let encodedSupportMessage = '';

    if (cpf || cnpj) {
      let wppMessage = `Olá! Preciso de suporte com o rastreamento da minha encomenda! O CPF/CNPJ é ${
        cpf || cnpj
      }`;

      if (notaFiscal) {
        wppMessage += ` e a nota fiscal é ${notaFiscal}. `;
      }

      encodedSupportMessage = encodeUrl(wppMessage);
    } else {
      encodedSupportMessage = encodeUrl(
        'Olá, preciso de ajuda para rastrear minha encomenda. '
      );
    }

    return `https://api.whatsapp.com/send?phone=${SUPPORT_NUMBER}&text=${encodedSupportMessage}`;
  }, [cpf, cnpj, notaFiscal]);

  return (
    <Container>
      <p>Histórico detalhado</p>

      <VerticalStepper
        etapaData={etapaData}
        etapas={etapas}
        ocorrenciaMaisRecente={ocorrenciaMaisRecente}
      />

      <button type="button" onClick={() => navigate('/rastreamento')}>
        Fazer novo rastreamento
      </button>

      <SupportLink href={whatsappSupportLink} target="_blank" rel="noreferrer">
        <FaWhatsapp />
        Suporte via WhatsApp
      </SupportLink>
    </Container>
  );
};

export default ResultadoRightSection;
