import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import Skeleton from 'react-loading-skeleton';
import { FaWhatsapp } from 'react-icons/fa';
import encodeUrl from 'encodeurl';

import HorizontalStepper from './HorizontalStepper';
import SocialMediaButtons from '../SocialMediaButtons';

import { SUPPORT_NUMBER } from '@/config';

import { IRastreamentoResponse } from '@/dtos/pages/resultado';

import {
  Container,
  DescricaoContainer,
  DetailsContainer,
  SupportLink,
} from './styles';

interface ResultadoLeftSectionProps {
  cpf: string;
  cnpj: string;
  notaFiscal: number;
  tipo: string;
  rastreamentoResponse: IRastreamentoResponse;
}

const ResultadoLeftSection: React.FC<ResultadoLeftSectionProps> = ({
  cpf,
  cnpj,
  notaFiscal,
  tipo,
  rastreamentoResponse,
}): JSX.Element => {
  const whatsappSupportLink = useMemo(() => {
    let encodedSupportMessage = '';

    if (cpf || cnpj) {
      let wppMessage = `Olá! Preciso de suporte com o rastreamento da minha encomenda! O CPF/CNPJ é ${
        cpf || cnpj
      }`;

      if (notaFiscal) {
        wppMessage += ` e a nota fiscal é ${notaFiscal}. `;
      }

      encodedSupportMessage = encodeUrl(wppMessage);
    } else {
      encodedSupportMessage = encodeUrl(
        'Olá, preciso de ajuda para rastrear minha encomenda. '
      );
    }

    return `https://api.whatsapp.com/send?phone=${SUPPORT_NUMBER}&text=${encodedSupportMessage}`;
  }, [cpf, cnpj, notaFiscal]);

  return (
    <Container>
      <h1>Status da encomenda</h1>

      <h2>{rastreamentoResponse.etapaAtual || <Skeleton width={100} />}</h2>

      <HorizontalStepper
        etapas={rastreamentoResponse?.etapas}
        ocorrenciaMaisRecente={rastreamentoResponse?.ocorrenciaMaisRecente}
      />

      <DescricaoContainer>
        <p>
          Destino:{' '}
          <span>
            {rastreamentoResponse?.destino || <Skeleton width={100} />}
          </span>
        </p>

        <p>
          Previsão de entrega:{' '}
          <span>
            {rastreamentoResponse?.previsaoEntrega || <Skeleton width={100} />}
          </span>
        </p>

        <p>
          {rastreamentoResponse?.ocorrenciaMaisRecente?.ocorrencia
            .descricao || (
            <>
              <Skeleton />
              <Skeleton />
            </>
          )}
        </p>
      </DescricaoContainer>

      <DetailsContainer>
        <p>
          Nota fiscal: <span>{notaFiscal}</span>
        </p>

        <p>
          Remetente:{' '}
          <span>
            {rastreamentoResponse?.remetente || <Skeleton width={180} />}
          </span>
        </p>

        <p>
          Destinatário:{' '}
          <span>
            {rastreamentoResponse?.destinatario || <Skeleton width={180} />}
          </span>
        </p>
      </DetailsContainer>

      <button type="button" onClick={() => navigate('/rastreamento')}>
        Fazer novo rastreamento
      </button>

      <SupportLink href={whatsappSupportLink} target="_blank" rel="noreferrer">
        <FaWhatsapp />
        Suporte via WhatsApp
      </SupportLink>

      <SocialMediaButtons
        notaFiscal={notaFiscal}
        cpf={cpf}
        cnpj={cnpj}
        tipo={tipo}
      />
    </Container>
  );
};

export default ResultadoLeftSection;
