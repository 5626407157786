import styled from 'styled-components';

export const ResultadoWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 100vw;

  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 30px 169px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1114px) {
    justify-content: center;
  }
`;

const SectionWrapper = styled.section`
  display: flex;

  justify-content: flex-start;

  width: 100%;
  max-width: 1380px;
`;

export const BreadCrumbsContainer = styled(SectionWrapper)`
  margin: 40px 0px;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const ResultadoContainer = styled(SectionWrapper)`
  @media (max-width: 1024px) {
    flex-direction: column;
  }

  @media (max-width: 650px) {
    margin-top: 40px;
  }
`;
