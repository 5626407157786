import { SITE_URL } from '@/config';
import React, { useMemo } from 'react';
import {
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share';

import { Container } from './styles';

interface ISocialMediaButtonsProps {
  notaFiscal: number;
  cpf?: string;
  cnpj?: string;
  tipo: string;
}

const SocialMediaButtons: React.FC<ISocialMediaButtonsProps> = ({
  notaFiscal,
  cpf,
  cnpj,
  tipo,
}) => {
  const message = useMemo(() => {
    const isCpfOrCnpj = cpf ? 'cpf' : 'cnpj';

    const tipoQueryParam = isCpfOrCnpj === 'cpf' ? '' : `&tipo=${tipo}`;

    return `Esse é o rastreamento da encomenda com nota fiscal ${notaFiscal}\n\n🔎🚚 ${SITE_URL}/rastreamento/resultado?${isCpfOrCnpj}=${
      cpf || cnpj
    }&notaFiscal=${notaFiscal}${tipoQueryParam}`;
  }, [notaFiscal, cpf, cnpj, tipo]);

  return (
    <Container>
      <p>Compartilhe:</p>

      <WhatsappShareButton url={message} windowWidth={1200} windowHeight={900}>
        <WhatsappIcon size={28} round={true} />
      </WhatsappShareButton>

      <EmailShareButton
        subject="Rastreamento no site da Transporte Generoso"
        url={message}
      >
        <EmailIcon size={28} round={true} iconFillColor="white" />
      </EmailShareButton>
    </Container>
  );
};

export default SocialMediaButtons;
