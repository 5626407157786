import styled from 'styled-components';
import MuiStepper from '@material-ui/core/Stepper';
import { StepLabel } from '@material-ui/core';

import { colors } from '@/styles/GlobalStyle';

interface IOcorrenciaTitleContainerProps {
  hasOcorrencia: boolean;
  informationType: 'info' | 'aviso' | 'alerta';
}

const MAPPED_COLOR = {
  info: colors.green200,
  aviso: colors.yellow200,
  alerta: colors.red,
};

export const Container = styled.div`
  width: 100%;
  max-width: 240px;
`;

export const Stepper = styled(MuiStepper)`
  padding: 0 !important;
  margin-top: 16px;

  .MuiStep-root.MuiStep-vertical {
    /* border: 1px solid tomato; */
    width: 100%;
    max-width: 500px;
    min-width: fit-content;
  }

  .MuiTypography-root.MuiStepLabel-label.MuiStepLabel-active.MuiTypography-body2.MuiTypography-displayBlock {
    color: ${colors.gray600};
    font-size: 16px;
    font-weight: 600;
    font-family: Poppins;
  }
`;

export const OcorrenciaTitleContainer = styled(
  StepLabel
)<IOcorrenciaTitleContainerProps>`
  // Título a direita da Bolinha
  .MuiStepLabel-label {
    margin-left: 20px;
  }

  // Texto da Bolinha
  .MuiStepIcon-text {
    display: none;
  }

  .MuiStepIcon-root.MuiStepIcon-completed,
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${(props) =>
      props.hasOcorrencia
        ? `${MAPPED_COLOR[props.informationType]}`
        : colors.gray300};
  }

  // Bolinha do estado de completo/finalizado
  .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active.MuiStepIcon-completed {
    color: ${colors.green200};
  }
`;
