import React, { useCallback, useMemo } from 'react';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import Skeleton from 'react-loading-skeleton';

import CircleX from '@/images/paginas/rastreamento/resultado/x.svg';

import {
  Container,
  Stepper,
  OcorrenciaDescriptionContainer,
  OcorrenciaTitleContainer,
} from './styles';

import {
  IEtapaData,
  IEtapas,
  IOcorrenciaMaisRecente,
} from '@/dtos/pages/resultado';

type VerticalStepperProps = {
  etapaData: IEtapaData;
  etapas: IEtapas;
  ocorrenciaMaisRecente: IOcorrenciaMaisRecente;
};

const VerticalStepper: React.FC<VerticalStepperProps> = ({
  etapaData,
  etapas,
  ocorrenciaMaisRecente,
}): JSX.Element => {
  const ultimaEtapa = useMemo(() => {
    let ultimaEtapa = 1;

    etapaData?.forEach((etapaObject) => {
      if (etapaObject.etapa > ultimaEtapa) {
        ultimaEtapa = etapaObject.etapa;
      }
    });

    return ultimaEtapa;
  }, [etapaData]);

  const isCompleted = useMemo(() => {
    if (
      ocorrenciaMaisRecente &&
      ocorrenciaMaisRecente.etapa === 4 &&
      ocorrenciaMaisRecente?.ocorrencia.tipoInformacao === 'info'
    ) {
      return true;
    }

    return false;
  }, [ocorrenciaMaisRecente]);

  const getStepErrorIcon = useCallback(
    (etapa: number) => {
      if (
        ocorrenciaMaisRecente?.ocorrencia.tipoInformacao === 'alerta' &&
        ocorrenciaMaisRecente.etapa === etapa
      ) {
        return () => (
          <img src={CircleX} alt="alert circle" width={24} height={24} />
        );
      }

      return undefined;
    },
    [ocorrenciaMaisRecente]
  );

  const orderedEtapas = useMemo(
    () => etapas?.sort((a, b) => b.etapa - a.etapa),
    [etapas]
  );

  return (
    <Container>
      <Stepper activeStep={0} orientation="vertical">
        {/* ESQUELETO DO COMPONENTE (<Skeleton />)*/}
        {!etapas &&
          [0, 1, 2, 3].map((number) => (
            <Step active={true} key={number}>
              <OcorrenciaTitleContainer
                hasOcorrencia={false}
                informationType="info"
                StepIconComponent={() => (
                  <Skeleton
                    width={24}
                    height={24}
                    style={{ borderRadius: 12 }}
                  />
                )}
              >
                <Skeleton />
              </OcorrenciaTitleContainer>

              <OcorrenciaDescriptionContainer>
                <Skeleton />

                <Typography>
                  <Skeleton />
                </Typography>
              </OcorrenciaDescriptionContainer>
            </Step>
          ))}

        {orderedEtapas?.map(({ etapa, nome }) => {
          const etapaIndex = etapaData?.findIndex(
            (etapaObject) => etapaObject.etapa === etapa
          );

          if (etapa > ultimaEtapa) {
            return;
          }

          return (
            <Step
              completed={etapa === 4 && isCompleted}
              active={true}
              key={etapa}
            >
              <OcorrenciaTitleContainer
                hasOcorrencia={etapa === ocorrenciaMaisRecente?.etapa}
                informationType={
                  ocorrenciaMaisRecente?.ocorrencia.tipoInformacao
                }
                StepIconComponent={getStepErrorIcon(etapa)}
              >
                {nome}
              </OcorrenciaTitleContainer>

              {etapaIndex !== -1 ? (
                etapaData[etapaIndex].ocorrencias.map((ocorrencia, index) => (
                  <OcorrenciaDescriptionContainer
                    key={etapa + ocorrencia.descricao + index}
                  >
                    <p>
                      <span>
                        {ocorrencia.data} - {ocorrencia.hora}
                      </span>
                    </p>

                    <Typography>
                      <b>
                        {ocorrencia.cidade} - {ocorrencia.estado}, Filial:{' '}
                        {ocorrencia.filial}.
                      </b>
                      {ocorrencia.descricao}
                    </Typography>
                  </OcorrenciaDescriptionContainer>
                ))
              ) : (
                <OcorrenciaDescriptionContainer />
              )}
            </Step>
          );
        })}
      </Stepper>
    </Container>
  );
};

export default VerticalStepper;
